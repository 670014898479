import UAParser from 'ua-parser-js';

const __BrowserMinVersions = {
    chrome: 64,
}

export class BrowserDetector {
    constructor(userAgentString) {
        this.parser = new UAParser(userAgentString);
        this.init();
    }

    init() {
        try {
            this.browserData = this.parser.getBrowser();
            this.osData = this.parser.getOS();
            this.deviceData = this.parser.getDevice();
        } catch (error) {
            console.error("BrowserDetector initialization failed:", error);
            this.browserData = {name: "unknown", version: "unknown"};
            this.osData = {name: "unknown", version: "unknown"};
            this.deviceData = {model: "unknown", type: "unknown", vendor: "unknown"};
        }
    }

    isBrowserChrome() {
        return this.getBrowserName() === 'Chrome';
    }

    getBrowserName() {
        return this.browserData.name || "unknown";
    }

    getBrowserVersion() {
        return this.browserData.version || "unknown";
    }

    isBrowserSupported() {
        if (!this.isUserAgentValid()) {
            return false;
        }
        
        const browserName = this.getBrowserName().toLowerCase();
        const browserVersion = parseInt(this.getBrowserVersion().split('.')[0], 10);

        if (browserName in __BrowserMinVersions) {
            return browserVersion >= __BrowserMinVersions[browserName];
        }

        return false;
    }

    isUserAgentValid() {
        const userAgentPatterns = [/Mozilla\/\d+\.\d+ .+/];
        return userAgentPatterns.some(pattern => pattern.test(this.parser.getUA()));
    }
}
