<script>
import {mapGetters} from 'vuex';
import {BrowserDetector} from "@/utils/browserDetector";

const _7DAYS_TO_TIMESTAMP = 1000 * 60 * 60 * 24 * 7;

export default {
  data() {
    return {
      pageLoaded: false,
      isHideFor7Days: false,
    }
  },
  computed: {
    ...mapGetters('config', ['hideBrowserWarningFor7DaysStartedAt']),
    isSetHideBrowserWarningConfig() {
      return this.hideBrowserWarningFor7DaysStartedAt !== 0;
    },
    isHiddenBrowserWaningFor7Days() {
      if (!this.isSetHideBrowserWarningConfig) {
        return false;
      }

      const expiry = new Date(this.hideBrowserWarningFor7DaysStartedAt + _7DAYS_TO_TIMESTAMP);
      return expiry.getTime() > new Date().getTime();
    },
    isLobby() {
      const page = this.$route.name;

      if (page) {
        return page === 'lobby';
      }

      return window.location.pathname === '/';
    }
  },
  watch: {
    isHideFor7Days(value) {
      if (!value) {
        this.$store.commit('config/SET_HIDE_BROWSER_WARNING_FOR_7DAYS_STARTED_AT', 0);
      } else {
        if (!(this.isSetHideBrowserWarningConfig && this.isHiddenBrowserWaningFor7Days)) {
          this.$store.commit('config/SET_HIDE_BROWSER_WARNING_FOR_7DAYS_STARTED_AT', new Date().getTime());
        }

        this.pageLoaded = false;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      const detector = new BrowserDetector(navigator.userAgent);
      this.pageLoaded = !detector.isBrowserSupported() && !this.isHiddenBrowserWaningFor7Days && this.isLobby;
    });
  },
  created() {
    this.isHideFor7Days = this.isHiddenBrowserWaningFor7Days;
  },
}
</script>

<template>
  <!-- 브라우저 권장 -->
  <transition name="fade-slide">
    <div v-if="pageLoaded" id="wrap_recommend" class="wrap_recommend" :class="{ loading: !pageLoaded }">
      <div class="inner_recommend">
        <div class="txt">
          <div class="fs-3 mb-1 fw-bold">이 사이트는 <span class="yellow">최신 크롬에 최적화</span>되어 있습니다.</div>
          <div class="fs-5">접속하신 브라우저(또는 버전)는 서비스 이용이 원활하지 않을 수 있으니 최신 크롬 브라우저 사용을 권장합니다.</div>
        </div>
        <div class="img">
          <img src="@/assets/v2/svg/ico_chrome.svg" alt="" width="35">
        </div>
      </div>
      <div class="form-check float-end d-inline-block">
        <input
          class="form-check-input d-inline-block me-2"
          type="checkbox" value=""
          id="not_look"
          style="width:1.3rem;height:1.3rem;"
          v-model="isHideFor7Days">
        <label class="form-check-label fs-5" for="not_look">
          7일 동안 보지 않기
        </label>
      </div>
      <button type="button" class="btn-close"
              @click.prevent.stop="pageLoaded = false"></button>
    </div>
    <!-- //브라우저 권장 -->
  </transition>
</template>

<style scoped lang="scss">
#wrap_recommend.loading {
  transition: all 0.5s ease;
  opacity: 0;
  transform: translateY(-20px);
}

.fade-slide-enter-active, .fade-slide-leave-active {
  transition: all 0.5s ease;
}

.fade-slide-enter, .fade-slide-leave-to /* .fade-slide-leave-active in <2.1.8 */
{
  opacity: 0;
  transform: translateY(-20px);
}

.fade-slide-enter-to, .fade-slide-leave /* .fade-slide-leave-active for <2.1.8 */
{
  opacity: 1;
  transform: translateY(0);
}
</style>